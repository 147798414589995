<template>
  <div>
    <div class="text-h4 pt-5">Контент страницы</div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" scrollable max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on">
                Создать
              </v-btn>
            </template>
            <v-card class="pa-1">
              <v-card-title class="mb-5 align-start">
                <span class="text-h5 mr-2">Создать страницу</span>
              </v-card-title>

              <v-card-text>
                <v-row class="pt-4">
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      ref="editedItem.name"
                      v-model="editedItem.name"
                      label="Название"
                      outlined
                      dense
                      :rules="[rules.required]"
                      :error="errors.name ? true : false"
                      :error-messages="errors.name"
                      @focus="errors.name = null"
                      @blur="slugPaste(editedItem.name)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      ref="editedItem.slug"
                      v-model="editedItem.slug"
                      :rules="[rules.required]"
                      :error="errors.slug ? true : false"
                      :error-messages="errors.slug"
                      label="Slug"
                      @input="slugValue(editedItem.slug)"
                      @focus="
                        errors.slug = null;
                        slugPaste(editedItem.name);
                      "
                      @blur="slugPaste(editedItem.name)"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <div class="d-inline-block">
                      <v-switch
                        color="primary"
                        class="mt-0"
                        v-model="editedItem.visibility"
                        disabled
                        label="Видимость"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="close"> Отмена </v-btn>
                <v-btn color="accent" @click="save"> Создать </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <template>
              <v-card class="pa-1">
                <v-card-title
                  class="text-h5 text-center justify-center flex-column mb-5"
                  >Удалить страницу?<br />
                  <span class="accent--text">{{
                    itemCurrentName
                  }}</span></v-card-title
                >

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn color="accent" outlined @click="closeDelete"
                    >Отмена</v-btn
                  >
                  <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">
        <div class="d-inline-block">
          <v-switch
            v-model="item.visibility"
            color="primary"
            class="my-0"
            hide-details
            :ripple="false"
            @change="changeStatus(item)"
          ></v-switch>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="
            $router.push({ name: 'ContentPagesEdit', params: { id: item.id } })
          "
        >
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "../../../api";
import { mapGetters } from "vuex";
import rules from "@/mixins/rules";
import slug from "@/mixins/slug";

export default {
  name: "PagesTable",
  mixins: [rules, slug],
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Название",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Slug",
          value: "slug",
          align: "center",
        },
        {
          text: "Видимость",
          value: "active",
          align: "center",
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      items: [],
      optionsTable: {},
      total: 0,
      editedItem: -1,
      loading: false,
      errors: {},
    };
  },

  computed: {
    ...mapGetters(["languagesList"]),
    itemCurrentName() {
      return this.editedItem.name || "";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getItems();
    this.editedItem = { ...this.createItem() };
  },
  methods: {
    async editItem(item) {
      this.$emit("edit", item);
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = this.items[this.editedIndex];
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.removeItem(this.items[this.editedIndex].id);
      this.getItems();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
        this.errors = {};
        this.resetErrors(this.editedItem, "editedItem");
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
      });
    },

    async save() {
      let formHasErrors = this.validationObject(this.editedItem, "editedItem");
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      if (this.editedIndex > -1) {
        await this.updateItem();
      } else {
        await this.addItem();
      }
      this.getItems();
      this.close();
    },

    async getItems() {
      this.loading = true;
      try {
        const res = await api.content.pagesList();
        this.items = res.data;
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async addItem() {
      const data = this.editedItem;
      try {
        await api.content.pageAdd(data);
        this.$store.dispatch("showSnackbar", {
          msg: `Страница "${this.editedItem.name.ru}" создана`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
      }
    },

    async updateItem() {
      const data = this.editedItem;
      try {
        await api.content.pageUpdate(this.editedItem.id, data);
        this.$store.dispatch("showSnackbar", {
          msg: `Видимость страницы "${this.editedItem.name.ru}" изменена`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка редактирования",
            color: "error",
            show: true,
          });
        }
      }
    },

    async removeItem(id) {
      try {
        const nameDelete = this.items[this.editedIndex].name;
        await api.content.pageDelete(id);
        this.$store.dispatch("showSnackbar", {
          msg: `Страница "${nameDelete}" удалена`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },

    async changeStatus(item) {
      await api.content.pageVisibility(item.id, item.visibility);
    },

    createItem() {
      const keys = [];
      let localeKeys = {};
      keys.forEach((key) => {
        let obj = {};
        this.languagesList.forEach((lang) => {
          obj[lang.iso] = "";
        });
        localeKeys[key] = obj;
      });
      return {
        ...localeKeys,
        name: "",
        slug: "",
        visibility: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
