var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h4 pt-5"},[_vm._v("Контент страницы")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"accent","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Создать ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-1"},[_c('v-card-title',{staticClass:"mb-5 align-start"},[_c('span',{staticClass:"text-h5 mr-2"},[_vm._v("Создать страницу")])]),_c('v-card-text',[_c('v-row',{staticClass:"pt-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{ref:"editedItem.name",attrs:{"label":"Название","outlined":"","dense":"","rules":[_vm.rules.required],"error":_vm.errors.name ? true : false,"error-messages":_vm.errors.name},on:{"focus":function($event){_vm.errors.name = null},"blur":function($event){return _vm.slugPaste(_vm.editedItem.name)}},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{ref:"editedItem.slug",attrs:{"rules":[_vm.rules.required],"error":_vm.errors.slug ? true : false,"error-messages":_vm.errors.slug,"label":"Slug","outlined":"","dense":""},on:{"input":function($event){return _vm.slugValue(_vm.editedItem.slug)},"focus":function($event){_vm.errors.slug = null;
                      _vm.slugPaste(_vm.editedItem.name);},"blur":function($event){return _vm.slugPaste(_vm.editedItem.name)}},model:{value:(_vm.editedItem.slug),callback:function ($$v) {_vm.$set(_vm.editedItem, "slug", $$v)},expression:"editedItem.slug"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-inline-block"},[_c('v-switch',{staticClass:"mt-0",attrs:{"color":"primary","disabled":"","label":"Видимость"},model:{value:(_vm.editedItem.visibility),callback:function ($$v) {_vm.$set(_vm.editedItem, "visibility", $$v)},expression:"editedItem.visibility"}})],1)])],1)],1),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","outlined":""},on:{"click":_vm.close}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.save}},[_vm._v(" Создать ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[[_c('v-card',{staticClass:"pa-1"},[_c('v-card-title',{staticClass:"text-h5 text-center justify-center flex-column mb-5"},[_vm._v("Удалить страницу?"),_c('br'),_c('span',{staticClass:"accent--text"},[_vm._v(_vm._s(_vm.itemCurrentName))])]),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)]],2)],1)]},proxy:true},{key:"item.active",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-inline-block"},[_c('v-switch',{staticClass:"my-0",attrs:{"color":"primary","hide-details":"","ripple":false},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.visibility),callback:function ($$v) {_vm.$set(item, "visibility", $$v)},expression:"item.visibility"}})],1)]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({ name: 'ContentPagesEdit', params: { id: item.id } })}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }